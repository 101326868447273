import ListItemSchema, {
  I18nListItemSchema,
  ListItemSchemaNullable
} from 'schemas/listItem.schema'
import { z } from 'zod'

export const listItemValidator = ListItemSchema

export const listItemValidatorNullable = ListItemSchemaNullable

export const i18nListItem = I18nListItemSchema

export type IListItem = z.infer<typeof listItemValidator>
export type IListI18nItem = z.infer<typeof i18nListItem>

export default listItemValidator
