import { z } from 'zod'

const ListItemSchema = z.object({
  id: z.string().uuid().optional().nullable(),
  value: z.string(),
  label: z.string().optional(),
  languageCode: z.string().default('en').nullable(),
  order: z.number().optional()
})

export const ListItemSchemaNullable = ListItemSchema.extend({
  id: z.string().uuid().optional().nullable(),
  value: z.string().nullable(),
  label: z.string().optional().nullable(),
  languageCode: z.string().nullable(),
  order: z.number().optional()
})

export const I18nListItemSchema = z.object({
  value: z.string(),
  languageCode: z
    .string()
    .nullable()
    .transform((x) => x ?? 'en'),
  isDefaultValue: z.boolean().optional().default(false)
})

export default ListItemSchema
