import { useCallback, useEffect, useRef, useState } from 'react'
import { ISkillDto } from 'data-transfers/dto'
import { throttle } from 'throttle-debounce'

import { getDigitsCount, getTextWidth } from 'utils'

import type { IPreviewOptions } from './types'

const defaultSourcePreviewOptions = {
  itemPaddingPx: 40,
  gapPx: 8,
  containerPaddingPx: 30,
  maxItemWidthPx: 360,
  cssFontDescriptor: '400 14px Poppins'
}

const useDataSourcesSummary = (
  sources: ISkillDto[],
  sourcePreviewOptions?: IPreviewOptions
) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const [sourcesToDisplayCount, setSourcesToDisplayCount] = useState(1)

  const handleContainerResizeFactory = useCallback(
    (
        sourcesTextWidth: number[],
        additionalSourcesChipLength: number,
        ensureSourcePreviewOptions: Required<IPreviewOptions>
      ) =>
      () => {
        if (!containerRef.current) {
          return
        }

        const containerWidth = containerRef.current?.clientWidth || 0

        const widthNeededForAdditionalSourcesChip =
          additionalSourcesChipLength + ensureSourcePreviewOptions.itemPaddingPx
        let containerWidthLeft =
          containerWidth -
          widthNeededForAdditionalSourcesChip -
          ensureSourcePreviewOptions.containerPaddingPx
        let chipsThatFitIntoContainerCount = 0

        for (const textWidth of sourcesTextWidth) {
          const chipWidth = Math.min(
            ensureSourcePreviewOptions.maxItemWidthPx ?? Infinity,
            textWidth + ensureSourcePreviewOptions.itemPaddingPx
          )
          const widthNeededForCurrentChip =
            chipWidth + ensureSourcePreviewOptions.gapPx

          containerWidthLeft -= widthNeededForCurrentChip

          if (containerWidthLeft <= 0) {
            if (
              sourcesTextWidth.length - 1 === chipsThatFitIntoContainerCount &&
              widthNeededForAdditionalSourcesChip >= -containerWidthLeft
            ) {
              setSourcesToDisplayCount(sourcesTextWidth.length)
              return
            }

            setSourcesToDisplayCount(chipsThatFitIntoContainerCount)
            return
          }

          chipsThatFitIntoContainerCount++
        }

        setSourcesToDisplayCount(chipsThatFitIntoContainerCount)
      },
    []
  )

  useEffect(() => {
    const containerElement = containerRef.current
    if (!containerElement) {
      return
    }

    const ensureSourcePreviewOptions = {
      ...defaultSourcePreviewOptions,
      ...sourcePreviewOptions
    }

    const sourcesTextWidth = sources.map((source) =>
      getTextWidth(source.value, ensureSourcePreviewOptions.cssFontDescriptor)
    )

    const additionalSourcesChipContent = '0'.repeat(
      getDigitsCount(sources.length)
    )
    const additionalSourcesChipLength = getTextWidth(
      `+${additionalSourcesChipContent}`,
      ensureSourcePreviewOptions.cssFontDescriptor
    )

    const handleContainerResize = handleContainerResizeFactory(
      sourcesTextWidth,
      additionalSourcesChipLength,
      ensureSourcePreviewOptions
    )

    const resizeThrottleMs = 100
    const handlerContainerResizeDebounced = throttle(
      resizeThrottleMs,
      handleContainerResize
    )

    const observer = new ResizeObserver(handlerContainerResizeDebounced)
    observer.observe(containerElement)

    handleContainerResize()

    return () => {
      observer.unobserve(containerElement)
    }
  }, [handleContainerResizeFactory, sourcePreviewOptions, sources])

  return { containerRef, sourcesToDisplayCount }
}

export default useDataSourcesSummary
