import { ForwardedRef, forwardRef, useImperativeHandle } from 'react'
import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react'
import clsx from 'clsx'
import { ErrorMessage } from 'formik'

import { ButtonIcon } from 'components/buttons'
import { InputStyle } from 'components/Input'
import Spinner from 'components/Spinner'
import { TextTooltip } from 'components/Tooltip'
import { IconChevron, IconClose } from 'icons'

import { getZodMessage } from 'utils/validation.util'

import styles from 'styles/Scrollbar.module.scss'

import { DefaultInput, DefaultSelectItem } from '../components'
import { ItemElement } from '../types'
import { itemToStringDefault } from '../utils'

import { IAutocompleteProps, IForwardRefAutocompleteProps } from './types'
import useAutocomplete from './useAutocomplete'

function Autocomplete<Item>(
  props: IAutocompleteProps<Item>,
  ref: ForwardedRef<IForwardRefAutocompleteProps>
) {
  const {
    required,
    disabled,
    label,
    placeholder,
    className,
    name,
    helperText,
    tooltip,
    isLoading,
    selectorClassName,
    isClearable,
    onBlur,
    optionAs: Item = DefaultSelectItem as ItemElement<Item>,
    inputAs: Input = DefaultInput,
    itemToString = itemToStringDefault,
    ...rest
  } = props
  const {
    isOpen,
    items,
    refs,
    inputValue,
    context,
    floatingStyles,
    activeIndex,
    selectedIndex,
    selectedItem,
    onChange,
    onKeyDown,
    getReferenceProps,
    getFloatingProps,
    getItemProps,
    onClearSelected,
    onClearHandler,
    t
  } = useAutocomplete({ ...rest, itemToString })
  useImperativeHandle(
    ref,
    () => {
      return {
        clear: onClearSelected
      }
    },
    [onClearSelected]
  )
  const AutocompleteContent = (
    <div
      {...getReferenceProps({
        ref: refs.setReference,
        className: clsx(
          'flex w-full items-center items-stretch border-b-2 cursor-pointer p-2 pb-1 pr-4 transition-colors duration-300 ease-out bg-white',
          isOpen && !disabled ? 'border-gray-400' : 'border-gray-300',
          disabled ? 'cursor-auto' : '',
          selectorClassName
        )
      })}
    >
      <Input
        value={inputValue}
        selectedItem={selectedItem}
        onChange={onChange}
        onClear={onClearSelected}
        itemToString={itemToString}
        placeholder={placeholder}
        aria-autocomplete="list"
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        disabled={disabled}
        className={
          disabled
            ? 'cursor-auto bg-transparent placeholder:placeholder-gray-250'
            : ''
        }
      />

      <Spinner
        isVisible={isLoading}
        size={24}
        className="pointer-events-none self-center"
      />
      {isClearable && (
        <ButtonIcon
          onClick={onClearHandler}
          icon={IconClose}
          className="h-5 w-5 self-center"
          rounded
        />
      )}

      <div>
        <div
          className={clsx(
            'ml-1 mr-2 h-full  min-h-[1.25rem] border-l-2  transition-colors duration-300 ease-out ',
            isOpen && !disabled ? 'border-gray-400' : 'border-gray-300'
          )}
        ></div>
      </div>
      <div className="h-4 w-4 self-center">
        <IconChevron
          size={16}
          className={clsx(
            '  transition-all duration-300 ease-out',
            isOpen && !disabled ? 'text-gray-500' : 'rotate-180 text-gray-300'
          )}
        />
      </div>
    </div>
  )

  return (
    <>
      <div className={clsx(className, 'relative')}>
        <div>
          {label && (
            <label className={InputStyle.label}>
              {label}
              {required && <span className={InputStyle.required}>*</span>}
            </label>
          )}

          {tooltip ? (
            <TextTooltip tooltipContent={tooltip}>
              {AutocompleteContent}
            </TextTooltip>
          ) : (
            AutocompleteContent
          )}
        </div>
        <div className={InputStyle.info}>
          {helperText && <div className={InputStyle.helper}>{helperText}</div>}
          {name && (
            <ErrorMessage
              name={name}
              render={(msg) => (
                <div className="fade-in text-medium text-error-400 ">
                  {getZodMessage(msg, t(msg, { ns: 'zod' }))}
                </div>
              )}
            />
          )}
        </div>
      </div>

      <FloatingPortal>
        {isOpen && !disabled && (
          <FloatingFocusManager
            context={context}
            initialFocus={-1}
            visuallyHiddenDismiss
          >
            <div
              {...getFloatingProps({
                ref: refs.setFloating,
                className: clsx(
                  'overflow-y-auto rounded border border-gray-300 bg-white py-2 shadow-lg z-[200]',
                  styles.scrollbar
                ),
                style: floatingStyles
              })}
            >
              {items.map((item, index) => (
                <Item
                  key={index}
                  {...getItemProps(item, index)}
                  active={activeIndex === index}
                  selected={selectedIndex === index}
                  item={item}
                >
                  {itemToString(item)}
                </Item>
              ))}
              {!items.length && (
                <div className="select-none text-center text-gray-500">
                  {isLoading && `${t('loading', { ns: 'actions' })}...`}
                  {!isLoading && t('general.noOptions', { ns: 'actions' })}
                </div>
              )}
            </div>
          </FloatingFocusManager>
        )}
      </FloatingPortal>
    </>
  )
}

export default forwardRef(Autocomplete) as <T>(
  props: IAutocompleteProps<T> & {
    ref?: ForwardedRef<IForwardRefAutocompleteProps>
  }
) => ReturnType<typeof Autocomplete>
